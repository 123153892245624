import React from 'react'
import { Provider } from 'react-redux'
import DrawerWrapper from './src/common/drawers/DrawerWrapper'
import ModalWrapper from './src/common/modals/ModalWrapper'
import createStore from './store/createStore'


const App = ({ element }) => {
  
  const initialState = window.___INITIAL_STATE__ || {}
  const store = createStore(initialState)

  return (
    <Provider store={store}>
      <ModalWrapper />
      <DrawerWrapper />
      {element}
    </Provider>
  )
}

export default App
