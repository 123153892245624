import React, { useEffect, useState } from "react";
import CognitoForm from "@tylermenezes/cognitoforms-react";
import LoadingComponent from "../ui/LoadingComponent";

const FORM_ID = 94
// const FORM_ID = 98 // testing form

const OrgSubmissionFormDrawer = () => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true)
    const script = document.createElement('script')
    script.src = 'https://www.cognitoforms.com/s/bbN8iw1MJUqjPe6aHn-_rw'
    document.body.appendChild(script)
    script.addEventListener('load', () => {
      // eslint-disable-next-line no-undef
      Cognito.load('forms', { id: '94' })
      setLoading(false)
      // Cognito.load('forms', { id: '68' }) // test form
    })
  }, []);

  return (
    <div className="container bg-white py-3 px-1 px-md-5 pt-4">
      <div className="row">
        <div className="col-12">
          <h2 className="text-primary">Organization Submission Form</h2>
          {loading && <LoadingComponent />}
          <div className="cognito" />
          {/* <CognitoForm accountId={"bbN8iw1MJUqjPe6aHn-_rw"} formId={FORM_ID} loading={<LoadingComponent />} /> */}
        </div>
      </div>
    </div>
  );
};

OrgSubmissionFormDrawer.propTypes = {};

export default OrgSubmissionFormDrawer;
