import React from 'react'
const LocationDetailDrawer = ({ data }) => {
  if (!data) {
    return <div />
  }

  const {
    title,
    locationServiceArea: {
      primaryaddressLine1,
      primaryaddressCity,
      primaryaddressState,
      whatistheapproximatesizeofyourserviceareainmileagefromyourprimaryaddressorzipcodelistedabove,
      whichzipcodesdoyoucommonlyrescuefoodfrom,
      whichcountiesorcitiesdoyoucommonlyrescuefoodfrom
      // whattypeofphysicallocationcheckallthatapply,
      // doyouhaveyourownphysicallocationanofficeorheadquartersstorageordistributionfacilitydirectaidsiteorotherlocation2,
    },
    organizationInformation: {
      organizationtype,
      organizationbusinessstatus,
      publicemailaddress,
      publicphonenumber,
      website,
      organizationcharacteristicsselectallthatapply,
      foodstoragecharacteristics,
      transportcharacteristics,
      isyourorganizationamemberofthefoodrescuealliance2,
      isyourorganizationamemberofordoesithaveanaffiliationwithanothergroupsuchasfoodrescueusfeedingamericaorothers2,
      pleaselisttheaffilatedgrouphere
    },
    schedulingInformation: {
      whatdaysoftheweekdoyourescuefood,
      whattimeofyeardoyourescuefood,
      doyouoffersamedayrescuesorpickupoffood2,
      schedulewebsite,
      linktoapplication,
      whattimedoyoustartrescuingfoodonmostdays2,
      whattimedoyoustoprescuingfoodonmostdays2,
      howarerescuesandfoodpickupsscheduled
    },
    foodRescueDetails: {
      currentcapacityforrescueperindividualpickuplbsaverageorestimate,
      whattypeofstoragedoyouhaveforrescuedfood2,
      wheredoyourescuefoodfrom,
      wheredoyoudistributerescuedfoodto,
      whattypeoffoodsdoyourescue,
      whatrequirementsmustfooddonorsmeet,
      doesyourorganizationhaveanyotherspecialcertificationordesignation,
      doyouacceptfoodpastitsexpirationdatesellbydateorbestbydate,
      doyouhaveapreferredformatinwhichtoreceivedonations
    },
    serviceDetails: {
      showbio,
      pleaseprovideashortbio13sentencestoaccompanyyourlistingonthefoodrescuelocator,
      DoYouChargeForYourServices2,
      doyouprovidetaxdeductiondocumentationfordonors2
    }
  } = data

  const renderAddress = () => {
    const hasPrimaryAddress =
      primaryaddressLine1 && primaryaddressCity && primaryaddressState

    if (hasPrimaryAddress) {
      return (
        <>
          {primaryaddressLine1}. | {primaryaddressCity}, {primaryaddressState}
        </>
      )
    }
  }

  const renderOrganizationType = () => {
    const organizationPinType =
      organizationtype === 'Logistics Service Organization'
        ? 'icon-logistics'
        : 'icon-pin'

    if (organizationtype === 'Both Food Rescue and Logistics Organization') {
      return (
        <>
          <p className={`h5 mb-0 pb-0 icon-item icon-pin text-gray`}>
            Food Rescue Org
          </p>
          <br />
          <p className={`h5 mb-0 icon-item icon-logistics text-gray`}>
            Logistics Org
          </p>
        </>
      )
    }
    return (
      <span className={`h5 mb-0 icon-item ${organizationPinType} text-gray`}>
        {organizationtype}
      </span>
    )
  }

  // TODO: Come back and fix this...
  // https://foodrescuelocator.com/?lat=26.4560756&lng=-80.1350197&loc=cG9zdDozMjU4&orgName=Boca%20Helping%20Hands-%20Delray%20Beach
  // https://foodrescuelocator.com/?lat=35.0512488&lng=-92.458792&loc=cG9zdDozNDA3&orgName=Soul%20Food%20Cafe%20Mission
  const renderShowAffiliatedGroups = () => {
    // if (
    //   (showDataCheck(isyourorganizationamemberofthefoodrescuealliance2) &&
    //     isyourorganizationamemberofordoesithaveanaffiliationwithanothergroupsuchasfoodrescueusfeedingamericaorothers2[0] ===
    //       'Yes') ||
    //   isyourorganizationamemberofthefoodrescuealliance2[0] === 'Yes'
    // ) {
    //   let groups = ''
    //   if (showDataCheck(pleaselisttheaffilatedgrouphere)) {
    //     groups = pleaselisttheaffilatedgrouphere
    //   }
    //   if (
    //     showDataCheck(isyourorganizationamemberofthefoodrescuealliance2[0]) &&
    //     isyourorganizationamemberofthefoodrescuealliance2[0] === 'Yes'
    //   ) {
    //     groups = groups + ', '
    //   }
    //   if (isyourorganizationamemberofthefoodrescuealliance2[0] === 'Yes') {
    //     groups = groups + 'Food Rescue Alliance'
    //   }
    //   return (
    //     <div className='col-12 my-2'>
    //       <h6 className='text-thin'>Memberships or Affiliations</h6>
    //       <p>{groups}</p>
    //     </div>
    //   )
    // } else {
    //   return false
    // }
  }

  const showDataCheck = (data) => {
    const noDataCollected = Array.isArray(data)
      ? data.includes('No data collected')
      : data === 'No data collected'
    return data && !noDataCollected
  }

  const shouldShowHoursAndServiceDetailSection =
    showDataCheck(whatdaysoftheweekdoyourescuefood) ||
    showDataCheck(whattimedoyoustartrescuingfoodonmostdays2) ||
    showDataCheck(whattimedoyoustoprescuingfoodonmostdays2) ||
    showDataCheck(whattimeofyeardoyourescuefood) ||
    showDataCheck(
      whatistheapproximatesizeofyourserviceareainmileagefromyourprimaryaddressorzipcodelistedabove
    ) ||
    showDataCheck(whichcountiesorcitiesdoyoucommonlyrescuefoodfrom) ||
    showDataCheck(whichzipcodesdoyoucommonlyrescuefoodfrom)

  const shouldShowAboutSection =
    (showbio === 'yes' &&
      showDataCheck(
        pleaseprovideashortbio13sentencestoaccompanyyourlistingonthefoodrescuelocator
      )) ||
    showDataCheck(organizationcharacteristicsselectallthatapply) ||
    showDataCheck(foodstoragecharacteristics) ||
    showDataCheck(transportcharacteristics) ||
    renderShowAffiliatedGroups()

  const shouldShowDonationDetailSection =
    showDataCheck(wheredoyourescuefoodfrom) ||
    showDataCheck(wheredoyoudistributerescuedfoodto) ||
    showDataCheck(whattypeoffoodsdoyourescue) ||
    showDataCheck(whattypeofstoragedoyouhaveforrescuedfood2) ||
    showDataCheck(
      currentcapacityforrescueperindividualpickuplbsaverageorestimate
    ) ||
    showDataCheck(doyouoffersamedayrescuesorpickupoffood2) ||
    showDataCheck(howarerescuesandfoodpickupsscheduled) ||
    showDataCheck(whatrequirementsmustfooddonorsmeet) ||
    showDataCheck(doyouprovidetaxdeductiondocumentationfordonors2) ||
    showDataCheck(DoYouChargeForYourServices2) ||
    showDataCheck(linktoapplication) ||
    showDataCheck(schedulewebsite) ||
    showDataCheck(linktoapplication) ||
    showDataCheck(schedulewebsite)

  return (
    <div className='bg-white'>
      <div className='container-fluid bg-light py-3 px-1 px-md-5 pb-md-5 pt-4'>
        <div className='row'>
          <div className='col-12'>
            <h2 className='text-primary mb-0'>{title}</h2>
            <h3 className='h4 text-thin text-capitalize'>{renderAddress()}</h3>
          </div>

          <div className='col-12 my-3'>
            {showDataCheck(organizationtype) && renderOrganizationType()}
            {showDataCheck(organizationbusinessstatus) && (
              <div className='tag'>{organizationbusinessstatus}</div>
            )}
          </div>
        </div>
        <hr />
        <div className='row'>
          {showDataCheck(publicphonenumber) && (
            <div className='col-12 col-xl-4 d-flex align-items-center'>
              <a
                className='icon-item icon-phone text-bold'
                href={`tel:${publicphonenumber}`}
              >
                {publicphonenumber}
              </a>
            </div>
          )}
          {showDataCheck(publicemailaddress) && (
            <div className='col-12 col-xl-4 my-3 my-xl-0 d-flex align-items-center'>
              <a
                className='icon-item icon-email text-bold'
                href={`mailto:${publicemailaddress}`}
              >
                Send Email
              </a>
            </div>
          )}
          {showDataCheck(website) && (
            <div className='col-12 col-xl-4 d-flex align-items-center'>
              <a
                className='icon-item icon-web text-bold'
                target='_blank'
                href={website}
              >
                Visit Website
              </a>
            </div>
          )}
        </div>
      </div>
      {shouldShowHoursAndServiceDetailSection && (
        <div className='container-fluid py-3 px-1 px-md-5 text-gray'>
          <div className='row'>
            <div className='col-12 my-2'>
              <h4>
                <strong>Hours & Service Details</strong>
              </h4>
            </div>
            {showDataCheck(whatdaysoftheweekdoyourescuefood) &&
              showDataCheck(whattimedoyoustartrescuingfoodonmostdays2) &&
              showDataCheck(whattimedoyoustoprescuingfoodonmostdays2) && (
                <div className='col-12 my-2'>
                  <h6 className='text-thin'>Typical Hours of Operation</h6>
                  <p className=' mb-2'>
                    {whattimedoyoustartrescuingfoodonmostdays2} -{' '}
                    {whattimedoyoustoprescuingfoodonmostdays2}
                  </p>
                  <ul>
                    {whatdaysoftheweekdoyourescuefood.map((day) => (
                      <li className='p'>{day}</li>
                    ))}
                  </ul>
                </div>
              )}
            {showDataCheck(whattimeofyeardoyourescuefood) && (
              <div className='col-12 my-2'>
                <h6 className='text-thin'>Seasons We Rescue Food:</h6>
                <ul>
                  {whattimeofyeardoyourescuefood.map((item) => (
                    <li className='p'>{item}</li>
                  ))}
                </ul>
              </div>
            )}
            {showDataCheck(
              whatistheapproximatesizeofyourserviceareainmileagefromyourprimaryaddressorzipcodelistedabove
            ) && (
              <div className='col-12 my-2'>
                <h6 className='text-thin'>Approximate Service Area Size</h6>
                <p>
                  {
                    whatistheapproximatesizeofyourserviceareainmileagefromyourprimaryaddressorzipcodelistedabove
                  }
                </p>
              </div>
            )}
            {showDataCheck(
              whichcountiesorcitiesdoyoucommonlyrescuefoodfrom
            ) && (
              <div className='col-12 my-2'>
                <h6 className='text-thin'>Counties or Cities Served</h6>
                <p>{whichcountiesorcitiesdoyoucommonlyrescuefoodfrom}</p>
              </div>
            )}
            {showDataCheck(whichzipcodesdoyoucommonlyrescuefoodfrom) && (
              <div className='col-12 my-2'>
                <h6 className='text-thin'>ZIP Codes Served</h6>
                <p>{whichzipcodesdoyoucommonlyrescuefoodfrom}</p>
              </div>
            )}
            {/* {showDataCheck(whattypeofphysicallocationcheckallthatapply) && (
            <div className="col-12 my-2">
              <h6 className="text-thin">
                Type of physical locations
              </h6>
              <ul>
                {whattypeofphysicallocationcheckallthatapply.map((item) => (
                  <li className="p">{item}</li>
                ))}
              </ul>
            </div>
          )} */}
          </div>
        </div>
      )}

      {shouldShowAboutSection && (
        <div className='container-fluid py-3 px-1 px-md-5 bg-light text-gray'>
          <div className='row'>
            <div className='col-12 my-2'>
              <h4 className='text-gray'>
                <strong>About the Organization</strong>
              </h4>
            </div>
            {showbio === 'yes' &&
              showDataCheck(
                pleaseprovideashortbio13sentencestoaccompanyyourlistingonthefoodrescuelocator
              ) && (
                <div className='col-12 my-2'>
                  <h6 className='text-thin'>Bio</h6>
                  {
                    pleaseprovideashortbio13sentencestoaccompanyyourlistingonthefoodrescuelocator
                  }
                </div>
              )}
            {showDataCheck(organizationcharacteristicsselectallthatapply) && (
              <div className='col-12 my-2'>
                <h6 className='text-thin'>
                  Special Certification or Designation
                </h6>
                <ul>
                  {organizationcharacteristicsselectallthatapply.map((item) => (
                    <li className='p'>{item}</li>
                  ))}
                </ul>
              </div>
            )}
            {showDataCheck(
              doesyourorganizationhaveanyotherspecialcertificationordesignation
            ) && (
              <div className='col-12 my-2'>
                <h6 className='text-thin'>Organization characteristics</h6>
                <ul>
                  {doesyourorganizationhaveanyotherspecialcertificationordesignation.map(
                    (item) => (
                      <li className='p'>{item}</li>
                    )
                  )}
                </ul>
              </div>
            )}
            {showDataCheck(foodstoragecharacteristics) && (
              <div className='col-12 my-2'>
                <h6 className='text-thin'>Food storage characteristics</h6>
                <ul>
                  {foodstoragecharacteristics.map((item) => (
                    <li>{item}</li>
                  ))}
                </ul>
              </div>
            )}
            {showDataCheck(transportcharacteristics) && (
              <div className='col-12 my-2'>
                <h6 className='text-thin'>Transport characteristics</h6>
                <ul>
                  {transportcharacteristics.map((item) => (
                    <li>{item}</li>
                  ))}
                </ul>
              </div>
            )}
            {renderShowAffiliatedGroups()}
          </div>
        </div>
      )}

      {shouldShowDonationDetailSection && (
        <div className='container-fluid py-3 px-1 px-md-5 bg-white text-gray'>
          <div className='row'>
            <div className='col-12 my-2'>
              <h4 className='text-gray'>
                <strong>Donation Details</strong>
              </h4>
            </div>
            {showDataCheck(wheredoyourescuefoodfrom) && (
              <div className='col-12 my-2'>
                <h6 className='text-thin'>Where we rescue food from:</h6>
                <ul>
                  {wheredoyourescuefoodfrom.map((item) => (
                    <li className='p'>{item}</li>
                  ))}
                </ul>
              </div>
            )}
            {showDataCheck(wheredoyoudistributerescuedfoodto) && (
              <div className='col-12 my-2'>
                <h6 className='text-thin'>
                  Where we distribute rescued food to:
                </h6>
                <ul>
                  {wheredoyoudistributerescuedfoodto.map((item) => (
                    <li className='p'>{item}</li>
                  ))}
                </ul>
              </div>
            )}
            {showDataCheck(whattypeoffoodsdoyourescue) && (
              <div className='col-12 my-2'>
                <h6 className='text-thin'>Types of foods we rescue:</h6>
                <ul>
                  {whattypeoffoodsdoyourescue.map((item) => (
                    <li className='p'>{item}</li>
                  ))}
                </ul>
              </div>
            )}
            {showDataCheck(
              doyouacceptfoodpastitsexpirationdatesellbydateorbestbydate
            ) && (
              <div className='col-12 my-2'>
                <h6 className='text-thin'>
                  Accept Foods After Sell By, Best By and Expiration Date:
                </h6>
                <p>
                  {doyouacceptfoodpastitsexpirationdatesellbydateorbestbydate}
                </p>
              </div>
            )}
            {showDataCheck(
              doyouhaveapreferredformatinwhichtoreceivedonations
            ) && (
              <div className='col-12 my-2'>
                <h6 className='text-thin'>Preferred Donation preferences</h6>
                <ul>
                  {doyouhaveapreferredformatinwhichtoreceivedonations.map(
                    (item) => (
                      <li className='p'>{item}</li>
                    )
                  )}
                </ul>
              </div>
            )}
            {showDataCheck(whattypeofstoragedoyouhaveforrescuedfood2) && (
              <div className='col-12 my-2'>
                <h6 className='text-thin'>
                  Storage provided for rescued food:
                </h6>
                <p>{whattypeofstoragedoyouhaveforrescuedfood2}</p>
              </div>
            )}
            {showDataCheck(
              currentcapacityforrescueperindividualpickuplbsaverageorestimate
            ) && (
              <div className='col-12 my-2'>
                <h6 className='text-thin'>
                  Current capacity for individual pickup (lbs):
                </h6>
                <p>
                  {
                    currentcapacityforrescueperindividualpickuplbsaverageorestimate
                  }{' '}
                  lbs
                </p>
              </div>
            )}
            {showDataCheck(doyouoffersamedayrescuesorpickupoffood2) && (
              <div className='col-12 my-2'>
                <h6 className='text-thin'>Same Day Rescue Available?</h6>
                <p>{doyouoffersamedayrescuesorpickupoffood2}</p>
              </div>
            )}
            {showDataCheck(howarerescuesandfoodpickupsscheduled) && (
              <div className='col-12 my-2'>
                <h6 className='text-thin'>
                  How Food Rescues and Pick-ups are Scheduled
                </h6>
                <ul>
                  {howarerescuesandfoodpickupsscheduled.map((item) => (
                    <li className='p'>{item}</li>
                  ))}
                </ul>
              </div>
            )}
            {showDataCheck(whatrequirementsmustfooddonorsmeet) && (
              <div className='col-12 my-2'>
                <h6 className='text-thin'>Requirements for Food Donors</h6>
                <ul>
                  {whatrequirementsmustfooddonorsmeet.map((item) => (
                    <li className='p'>{item}</li>
                  ))}
                </ul>
              </div>
            )}
            {showDataCheck(doyouprovidetaxdeductiondocumentationfordonors2) && (
              <div className='col-12 my-2'>
                <h6 className='text-thin'>Tax Documentation Available?</h6>
                <p>{doyouprovidetaxdeductiondocumentationfordonors2}</p>
              </div>
            )}
            {showDataCheck(DoYouChargeForYourServices2) && (
              <div className='col-12 my-2'>
                <h6 className='text-thin'>Rescue Service Charge?</h6>
                <p>{DoYouChargeForYourServices2}</p>
              </div>
            )}
          </div>
        </div>
      )}

      {showDataCheck(linktoapplication) && showDataCheck(schedulewebsite) && (
        <div className='container-fluid py-3 px-1 px-md-5 bg-light text-gray'>
          <div className='row'>
            <div className='col-12'>
              <h4 className='text-gray'>
                <strong>Getting Involved</strong>
              </h4>
            </div>
            <div className='col-12'>
              <div>
                <h6 className='text-thin mt-4'>Volunteers can sign up here</h6>
                {showDataCheck(linktoapplication) && (
                  <a
                    href=''
                    target='_blank'
                    href={linktoapplication}
                    className='btn btn-dark'
                  >
                    Volunteer Application
                  </a>
                )}
                {showDataCheck(schedulewebsite) && (
                  <a href='' target='_blank' className='btn btn-dark'>
                    Food Donation Sign-Up Form
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

LocationDetailDrawer.propTypes = {}

export default LocationDetailDrawer
