import React from 'react'
// import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { Modal, Button } from 'react-bootstrap'
import { closeModal } from './modalActions'


const OrgTypeModal = ({heading}) => {
  const dispatch = useDispatch()

  return (
    <>
      <Modal size="lg" show onHide={() => dispatch(closeModal())}>
        <div className="container px-md-5 pt-md-4 pb-md-5" >
          <div className="row">
            <div className="col-12" >
              <Modal.Header closeButton className="d-flex justify-content-end p-0">
              </Modal.Header>
            </div>
          </div>
          <div className="row">
            <div className="col-12 text-center">
              <h3>What kind of organization are you?</h3>
            </div>
          </div>
          <div className="row no-gutters mt-2">
            <div className="col-1 d-flex align-items-start justify-content-end pe-0">
              <img src="/img/icons/food-rescue-org-icon.svg" alt="" role="presentation"/>
            </div>
            <div className="col-11">
              <strong>Food Rescue Organization:</strong>Food rescue organizations (FROs) conduct food rescue programming, which includes the retrieval of excess food from donors and/or the delivery of donated food directly to clients or to other organizations that redistribute food to clients. These FROs may also offer other programming, such as education/skills training and the repurposing of donated food into meals or other food products, etc.
            </div>
          </div>
          <div className="row no-gutters my-3">
            <div className="col-1 d-flex align-items-start justify-content-end pe-0">
              <img src="/img/icons/logistics-org-icon.svg" alt="" role="presentation"/>
            </div>
            <div className="col-11">
              <strong>Logistics Service Organizations:</strong>Logistics service organizations apply software solutions to coordinate the redistribution of excess food by volunteers or other organizations, but do not themselves provide resources such as labor, storage facilities, or vehicles.
            </div>
          </div>
          <div className="row no-gutters">

            <div className="col-11 offset-1">
              <strong>Both Food Rescue and Logistics Organizations:</strong>These are food rescue organizations that also leverage logistics software or application solutions to coordinate food redistribution as part of their food rescue and distribution efforts.
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

// TestModal.propTypes = {

// }

export default OrgTypeModal
