import { DRAWER_CLOSE, DRAWER_OPEN } from './drawerConstants';

export const openDrawer = (drawerType, drawerProps) => {
  return {
    type: DRAWER_OPEN,
    payload: {
      drawerType,
      drawerProps
    }
  }
}

export const closeDrawer = () => {
  return {
    type: DRAWER_CLOSE
  }
}