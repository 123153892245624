import React from 'react'
import PropTypes from 'prop-types'

const LoadingComponent = ({ inverted }) => {
  return (
    <div>
      <div className="loading-animation">
        <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
      </div>
    </div>
  )
}

LoadingComponent.propTypes = {
  inverted: PropTypes.bool
}

export default LoadingComponent
