import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import modalReducer from '../src/common/modals/modalReducer'
import drawerReducer from '../src/common/drawers/drawerReducer'
// import asyncReducer from '../common/async/asyncReducer'
// import modalReducer from '../common/modals/modalReducer'
// import drawerReducer from '../common/drawers/drawerReducer'


const rootReducer = (history) => combineReducers({
  router: connectRouter(history),
  // async: asyncReducer,
  modals: modalReducer,
  drawers: drawerReducer,
})

export default rootReducer