import { DRAWER_CLOSE, DRAWER_OPEN } from './drawerConstants';
import { createReducer} from '../../utilities/reducerUtil'

const initialState = null;

export const openDrawer = (state, payload) => {
  const { drawerType, drawerProps } = payload
  return { drawerType, drawerProps }
}

export const closeDrawer = (state, payload) => {
  return null
}

export default createReducer(initialState, {
  [DRAWER_OPEN]: openDrawer,
  [DRAWER_CLOSE]: closeDrawer
})