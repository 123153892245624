import React from 'react'

const TestDrawer = () => {
  return (
    <div>
      <p>Hello World</p>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h2 className="text-primary">Hello</h2>
          </div>
        </div>
      </div>
    </div>
  )
}

TestDrawer.propTypes = {

}

export default TestDrawer
